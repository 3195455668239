// extracted by mini-css-extract-plugin
export var layoutChapterReadMore = "layoutChapter-module--layoutChapterReadMore--q0Oti";
export var layoutChapterHero = "layoutChapter-module--layoutChapterHero--fQx98";
export var layoutChapterSmallText = "layoutChapter-module--layoutChapterSmallText--UzY08";
export var layoutChapterShareButtonContainer = "layoutChapter-module--layoutChapterShareButtonContainer--iDoq9";
export var layoutChapterSubheader = "layoutChapter-module--layoutChapterSubheader--EKZr3";
export var layoutChapterHoneyPot = "layoutChapter-module--layoutChapterHoneyPot--PlUYo";
export var layoutChapterCommentsContainer = "layoutChapter-module--layoutChapterCommentsContainer--ZtIYp";
export var layoutChapterCommentContainer = "layoutChapter-module--layoutChapterCommentContainer--ZcY3H";
export var layoutChapterComment = "layoutChapter-module--layoutChapterComment--eReZ2";
export var layoutChapterCommentName = "layoutChapter-module--layoutChapterCommentName--3iVhX";
export var layoutChapterLongRead = "layoutChapter-module--layoutChapterLongRead--i9dZu";